body{font-family: 'Work Sans', sans-serif;margin:0!important;padding:0!important;color:#ffffff;background:#141414}
h1{font-weight: 900;}
h2{font-weight: 700;}
h3{font-weight: 500;}
h4{font-weight: 700; text-transform: uppercase;}
h6{font-weight: 400;}
a{color:inherit; text-decoration: none;}
a:hover{font-weight: 500; color:inherit;}
.active{font-weight: 700;}
nav{
	background: #ffc107;
	color: #141414;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.10);
}

/*@media (prefers-color-scheme: dark) {*/
	.cut{
		flex: 0 1 33.33%;
		box-shadow: 0 6px 6px 0 rgba(0,0,0,0.25);
	}
	.rank{
		background: #0f0f0f;
		box-shadow: 0 6px 6px 0 rgba(0,0,0,0.25);
		border-radius: 6px;
		padding:20px;
	}
	.theme-btn{
		background-color: #141414;
		color:#ffffff;
	}
	.theme-btn:hover{
		background-color: #343434;
		color:#ffffff;
	}
	header,section,footer{
		z-index: 1;
	}
	section:nth-of-type(odd){
		background: transparent;
	}
	section:nth-of-type(even){
		background: #141414;
		z-index: -1;
	}
/* 	
	header::before{
		background: #141414;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		margin-top:300px;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: skewY(-6deg);
		z-index: -10;
	}
	section:nth-of-type(2)::before {
		background: #141414;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: skewY(6deg);
		z-index: -10;
	}
	section:nth-of-type(2)::after {
		background: #141414;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: skewY(-6deg);
		z-index: -10;
	}
}

@media (prefers-color-scheme: light) {
	.cut{
		flex: 0 1 33.33%;
	}
	.rank{
		background: #ffffff;
		border-radius: 6px;
		padding:20px;
	}
	.theme-btn{
		background-color: #f8f8f8;
		color:#0f0f0f;
	}
	.theme-btn:hover{
		background-color: #e8e8e8;
		color:#0f0f0f;
	}
	header,section,footer{
		position: relative;
		z-index: 1;
	}
	section:nth-of-type(odd){
		background: transparent;
	}
	section:nth-of-type(even){
		background: #f8f8f8;
		z-index: -1;
	}
	header::before{
		background: #f8f8f8;
		position: absolute;
		content: "";
		width: 100%;
		height: 50%;
		margin-top:200px;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: skewY(-6deg);
		z-index: -10;
	}
	header::after {
		background: #f8f8f8;
		position: absolute;
		content: "";
		width: 100%;
		height: 50%;
		bottom: 0;
		left: 0;
		z-index: -10;
	}
	section:nth-of-type(2)::before {
		background: #f8f8f8;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: skewY(6deg);
		z-index: -10;
	}
	section:nth-of-type(2)::after {
		background: #f8f8f8;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transform-origin: top left;
		transform: skewY(-6deg);
		z-index: -10;
	}
}
*/

.sticky-top-sm{
	position: sticky;
    top:0;
	z-index: 10;
}
@media only screen and (min-width: 768px){
	.sticky-top-sm{
		position: relative;
	}
}
@media only screen and (min-width: 992px){
	.sticky-top-sm{
		position: relative;
	}
}