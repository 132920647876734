html,body{
    font-size: calc(14px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    overflow:hidden;
}

::-webkit-scrollbar {
    display: none;
}

header{
    position: absolute;
    overflow: hidden !important;
    height: 100%;
    width: 100%;
}

.dynamic-content{
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    text-align: center;
    text-shadow: 0px 2px 12px rgba(0,0,0,0.5);
}
.dynamic-overlay{
    background-color: rgba(0,0,0,0.75);
    box-shadow: inset 0 0 100px black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: scroll;
}

.dynamic-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
    grid-auto-rows: 25vh;
    grid-auto-flow: dense;
    z-index: -1;
}

.small,.large,.wide,.tall{
    position: relative;
    overflow: hidden;
    margin:0 auto;
}
.small{
    width:50vw;
    height:25vh;
    grid-column: span 1;
    grid-row: span 1;
}
.large{
    width:100vw;
    height:50vh;
    grid-column: span 2;
    grid-row: span 2;
}
.wide{
    width:100vw;
    height:25vh;
    grid-column: span 2;
    grid-row: span 1;
}
.tall{
    width:50vw;
    height:50vh;
    grid-column: span 1;
    grid-row: span 2;
}
.gridImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo{
    min-width: 60px;
    width:6vw;
    max-width: 96px;
}

.mailchimp-form{
    position: relative;
    display: block;
    text-align: center;
    min-width: 240px;
    width:80%;
    max-width: 720px;
    margin: 0 auto;
}
.mailchimp-form input{
    width: 100%;
    color:rgba(255, 255, 255, 1);
    display: block;
    height: 2em;
    margin: 0.5em auto;
	border:none;
	border-radius: 2em;
	padding: 0 1em;
    background-color: rgba(255, 255, 255, .25);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
    backdrop-filter: blur(10px);
}
.mailchimp-fullname{
    display:flex;   
}
.mailchimp-fullname input:first-of-type{
    border-radius: 2em 0 0 2em;
    margin:1px;
}
.mailchimp-fullname input:last-of-type{
    border-radius: 0 2em 2em 0;
    margin:1px;
}
.mailchimp-form input:focus-visible{
    outline: none;
}
.mailchimp-form input::placeholder{
    color: rgba(255, 255, 255, 0.33);
}
.mailchimp-form button{
    display: inline-block;
    margin: 0.5em auto;
    height: 2em;
	border:none;
	border-radius: 2em;
	padding: 0 1em;
    background-color: rgb(255,193,7,.75);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
    backdrop-filter: blur(10px);
}
.mailchimp-form button:hover {background-color: rgb(255,193,7,1);}
.mailchimp-form button:active {
    background-color: rgb(255,193,7,1);
    box-shadow: 0 4px #000;
    transform: translateY(2px);
  }
.mailchimp-status{
    width: fit-content;
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translate(-50%);
}
.mailchimp-status p{
    font-size:0.8rem;
    background:rgba(0,0,0,0.75);
    margin:0;
    border-radius: 4px;
    box-shadow: 0px 2px 12px rgba(0,0,0,0.5);
    padding: 0.5em 1em;}
.mailchimp-status a{
    font-size:0.8rem;
    text-decoration: underline;
}

.contact-window, .contact-button{
    color:rgba(255, 255, 255, .5);
    position: fixed;
    right:0;
    z-index: 3;
    background-color: rgba(255, 255, 255, .25);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
    backdrop-filter: blur(10px);
    margin:24px;
}
.contact-window h6 {color:rgba(255, 255, 255, .75);font-size:0.9rem;}
.contact-window p {color:rgba(255, 255, 255, .5);font-size:0.8rem;}
.contact-window{
    right:0;
    bottom:72px;
    border-radius: 6px;
    padding:24px;
}
.contact-window:before {
    content: "";
    position: absolute;
    top: 100%;
    right: 12px;
    width: 0;
    border-top: 12px solid rgba(255, 255, 255, .25);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    backdrop-filter: blur(10px);
  }
.contact-button{
    font-size: 24px;
    line-height: 48px;
    width: 48px;
    height: 48px;
    bottom:0;
    text-align: center;
    border-radius: 100%;
    cursor: pointer;
}

@media only screen and (max-height:420px) {
    .dynamic-content {
        top:initial;
        transform: translate(-50%);
    }
}


@media only screen and (min-width: 720px) {
    .dynamic-grid{
        grid-template-columns: repeat(auto-fit, minmax(24vw, 1fr));
        grid-auto-rows: 25vh;
    }
    .small{
        width:25vw;
        height:25vh;
    }
    .large{
        width:50vw;
        height:50vh;
    }
    .wide{
        width:50vw;
        height:25vh;
    }
    .tall{
        width:25vw;
        height:50vh;
    }
}

@media only screen and (min-width: 1440px) {
    .dynamic-grid{
        grid-template-columns: repeat(auto-fit, minmax(16.666vw, 1fr));
        grid-auto-rows: 25vh;
    }
    .small{
        width:16.666vw;
        height:25vh;
    }
    .large{
        width:33.333vw;
        height:50vh;
    }
    .wide{
        width:33.333vw;
        height:25vh;
    }
    .tall{
        width:16.666vw;
        height:50vh;
    }
}